<template>
  <div>
    <!-- Your navigation content goes here -->
    <v-navigation-drawer
      class="white darken-1"
      elevation="12"
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
      bottom
      dense
      temporary
    >
      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-img
              :src="photoURL ? photoURL : require('@/assets/logo.png')"
              :alt="displayName"
            ></v-img>
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="title">
              Rupai Investment ltd
            </v-list-item-title>
            <v-list-item-subtitle>Email: Info@ril.com.bd</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>
      <v-list dense nav>
        <template v-for="item in items">
          <v-row v-if="item.heading" :key="item.heading" align="center">
            <v-col cols="12">
              <v-subheader v-if="item.heading">
                {{ item.heading }}
              </v-subheader>
            </v-col>
          </v-row>
          <v-list-group
            v-else-if="item.children"
            :key="item.title"
            v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            append-icon=""
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  <strong>{{ item.title }}</strong>
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              link
              :to="child.route"
              style="text-decoration: none"
            >
              <v-list-item-action v-if="child.icon">
                <v-icon :style="child.colors">{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  <strong>{{ child.title }}</strong>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item
            style="text-decoration: none"
            v-else
            :key="item.title"
            link
            :to="item.route"
          >
            <v-list-item-action>
              <v-icon :style="item.colors">{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <span class="d-flex justify-space-between align-center">
                  <span
                    ><strong>{{ item.title }}</strong></span
                  >
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar class="fixed-bar d-sm-flex" color="green lighten-1">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <div>
        <div class="d-flex align-center text-center">
          <v-btn style="text-transform: none" class="font-7" text plain to="/">
            <v-icon left color="red"> mdi-home </v-icon> Home</v-btn
          >

          <div class="text-center">
            <v-menu open-on-hover down offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  style="text-transform: none"
                  class="font-7"
                  text
                  plain
                  v-bind="attrs"
                  v-on="on"
                >
                  About US
                  <v-icon size="small" color="red">
                    mdi-arrow-down-drop-circle
                  </v-icon>
                </v-btn>
              </template>
              <!-- <v-card class="mx-auto" tile>
              <v-list rounded>
                <v-list-item-group v-model="selectedItem" color="primary">
                  <v-list-item v-for="(item, i) in AboutItems" :key="i">
                    <v-list-item-icon>
                      <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.text"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card> -->
              <v-list>
                <v-list-item-group v-model="selectedItem" color="primary">
                  <v-list-item to="/CorporateProfile"
                    ><v-list-item-title
                      >Company Profile</v-list-item-title
                    ></v-list-item
                  >
                  <v-divider></v-divider>
                  <v-list-item to="/BoardOfDirectors">
                    <v-list-item-title
                      >Board Of Directors</v-list-item-title
                    ></v-list-item
                  >

                  <v-divider></v-divider>
                  <v-list-item to="/AuditCommittee"
                    ><v-list-item-title
                      >Audit Committee</v-list-item-title
                    ></v-list-item
                  >
                  <v-divider></v-divider>
                  <v-list-item to="/BasicFeatures"
                    ><v-list-item-title
                      >Basic Features</v-list-item-title
                    ></v-list-item
                  >
                  <v-divider></v-divider>
                  <v-list-item to="BusinessPrinciples"
                    ><v-list-item-title
                      >Business Principles</v-list-item-title
                    ></v-list-item
                  >
                  <v-divider></v-divider>
                  <v-list-item to="Functions"
                    ><v-list-item-title
                      >Functions</v-list-item-title
                    ></v-list-item
                  >
                  <v-divider></v-divider>
                  <v-list-item to="KeyInformation"
                    ><v-list-item-title
                      >KeyInformation</v-list-item-title
                    ></v-list-item
                  >
                </v-list-item-group>
              </v-list>
            </v-menu>
          </div>
          <!-- <v-btn style="text-transform: none" class="font-7" text plain
          >About Us
          <v-icon size="small" color="red">
            mdi-arrow-down-drop-circle
          </v-icon></v-btn
        > -->
          <v-btn
            style="text-transform: none"
            class="font-7"
            text
            plain
            to="/notice"
            ><v-icon left color="red">mdi-bulletin-board</v-icon>Notice
            Board</v-btn
          >
          <v-btn
            style="text-transform: none"
            class="font-7"
            text
            plain
            to="/gallery"
            ><v-icon left color="red">mdi-view-gallery-outline</v-icon
            >Gallery</v-btn
          >
          <v-btn style="text-transform: none" class="font-7" text plain
            >Download</v-btn
          >
          <v-btn
            style="text-transform: none"
            class="font-7"
            text
            plain
            to="Overview"
            ><v-icon left color="red">mdi-lifebuoy</v-icon>Overview</v-btn
          >
          <v-btn
            style="text-transform: none"
            class="font-7"
            text
            plain
            to="IpoNotice"
            >IPO Lottery Result</v-btn
          >
          <v-btn
            style="text-transform: none"
            class="font-7"
            text
            plain
            to="/ContactUs"
            ><v-icon left color="red">mdi-card-account-mail-outline</v-icon
            >Contact Us</v-btn
          >
          <v-btn
            style="text-transform: none"
            class="font-7"
            text
            plain
            to="Career"
            >Career</v-btn
          >

          <!-- <v-btn
          style="text-transform: none"
          large
          class="font-7 white blue--text"
          >Blog</v-btn
        > -->
        </div>
      </div>
      <v-app-bar-nav-icon class="d-flex d-sm-none" />
    </v-app-bar>
  </div>
</template>

<script>
export default {
  data: () => ({
    selectedItem: 0,
    drawer: false,
    //
    items: [
      {
        title: "Home",
        icon: "mdi-view-dashboard",
        route: "/",
        colors: "color:#EF6C00",
      },
      {
        icon: "mdi-chevron-up",
        "icon-alt": "mdi-chevron-down",

        colors: "color:Orange",
        model: false,

        title: "About US",

        route: "",

        children: [
          {
            icon: "mdi-face-woman-profile",
            title: "Corporate profile",
            route: "CorporateProfile",
            colors: "color:blue",
          },
          {
            icon: "mdi-wallet-membership",
            title: "Board of Directors",
            route: "BoardOfDirectors",
            colors: "color:red",
          },
          {
            icon: "mdi-comment-multiple-outline",
            title: "Audit Committee",
            route: "AuditCommittee",
            colors: "color:red",
          },
          {
            icon: "mdi-image-filter-center-focus-weak",
            title: "Basic Features",
            route: "BasicFeatures",
            colors: "color:red",
          },
          {
            icon: "mdi-handshake",
            title: "Business Principles",
            route: "BusinessPrinciples",
            colors: "color:red",
          },
          {
            icon: "mdi-function",
            title: "Functions:",
            route: "Functions",
            colors: "color:red",
          },
          {
            icon: "mdi-information-box-outline",
            title: "Key Informaiton",
            route: "KeyInformation",
            colors: "color:red",
          },
        ],
      },
      {
        title: "Notice Board",
        icon: "mdi-bulletin-board",
        route: "notice",
        colors: "color:#EF6C00",
      },
      {
        title: "Gallery",
        icon: "mdi-view-gallery",
        route: "gallery",
        colors: "color:#EF6C00",
      },
      {
        title: "Download",
        icon: "mdi-download",
        route: "",
        colors: "color:#EF6C00",
      },
      {
        title: "Overview",
        icon: "mdi-view-array-outline",
        route: "/Overview",
        colors: "color:#EF6C00",
      },
      {
        title: "IPO Lottery Result",
        icon: "mdi-lock-pattern",
        route: "/IpoNotice",
      },
      {
        title: "Contact US",
        icon: "mdi-account-box-outline",
        route: "/ContactUs",
      },
      {
        title: "Careen",
        icon: "mdi-treasure-chest",
        route: "/Career",
      },
    ],
    admins: [
      ["Management", "mdi-account-multiple-outline"],
      ["Settings", "mdi-cog-outline"],
    ],
    cruds: [
      ["Create", "mdi-plus-outline"],
      ["Read", "mdi-file-outline"],
      ["Update", "mdi-update"],
      ["Delete", "mdi-delete"],
    ],
    AboutItems: [
      { title: "Real-Time", icon: "mdi-clock" },
      { title: "Audience", icon: "mdi-account" },
      { title: "Conversions", icon: "mdi-flag" },
    ],
  }),
};
</script>

<style scoped>
.fixed-bar {
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 0em;
  z-index: 2;
}
</style>
